module.exports = {
	"sidebar.section.main_menu": "เมนูหลัก",
	"sidebar.section.operation": "การปฏิบัติงาน",
	"sidebar.section.settings": "การตั้งค่า",
	"sidebar.company_settings" : "ตั้งค่าบริษัท",
	"sidebar.account_settings" : "ตั้งค่าผู้ใช้งาน",
	"sidebar.dashboard": "Dashboard",
	"sidebar.dashboard_main": "Company Dashboard",
	"sidebar.pos_dashboard": "POS Dashboard",
	"sidebar.pos": "หน้าร้าน",
	"sidebar.pos_sale_entry": "รายการขายสินค้าหน้าร้าน",
	"sidebar.pos_tax_invoice": "ใบกำกับภาษีเต็มรูปแบบ",
	"sidebar.pos_po_lsg": "ใบสั่งซื้อ (LSG)",
	"sidebar.pos_goodsrequest": "ใบเบิกสินค้า (LSG)",
	"sidebar.pos_goodsreturn": "ใบขอคืนสินค้า (LSG)",
	"sidebar.pos_goodsreceipt": "ใบรับสินค้า",
	"sidebar.pos_goodsadjust": "ปรับปรุงเพิ่มลดสินค้า",
	"sidebar.pos_shopsettings": "ตั้งค่าร้านค้า",
	"sidebar.pos_posshop": "ร้านค้า POS",
	"sidebar.pos_point": "แต้มสะสม",
	"sidebar.pos_pointforrewards": "รายการสมาชิกสะสมแต้ม",
	"sidebar.pos_pointforrewardhistory": "ประวัติการใช้แต้ม",
	"sidebar.pos_credit": "Pay Later",
	"sidebar.pos_creditcustomers": "รายการลูกค้าเครดิต",
	"sidebar.pos_creditorderhistory": "ประวัติ Pay Later และการชำระ",
	"sidebar.sale": "ขาย",
	"sidebar.sale_customer": "ลูกค้า",
	"sidebar.sale_qt": "ใบเสนอราคา",
	"sidebar.sale_so": "ใบสั่งขาย",
	"sidebar.sale_inv": "Invoice list by saleman",
	"sidebar.sale_pos": "รายการขาย POS",
	"sidebar.promotion": "โปรโมชั่น",
	"sidebar.sale_promotion": "รายการโปรโมชั่น",
	"sidebar.sale_promotiongroup": "กลุ่มสินค้าโปรโมชั่น",
	"sidebar.sale_visit": "Visit Plan",
	"sidebar.sale_calendar": "Sale Calendar",
	"sidebar.purchase": "จัดซื้อ",
	"sidebar.purchase_supplier": "ซัพพลายเออร์",
	"sidebar.purchase_po": "ใบสั่งซื้อ",
	"sidebar.purchase_rt": "ใบขอลดหนี้/ส่งคืนสินค้า",
	"sidebar.hr": "HR",
	"sidebar.hr_staff": "รายชื่อพนักงาน",
	"sidebar.hr_timesheet": "ตารางลงเวลาปฏิบัติงาน",
	"sidebar.hr_payroll": "บัญชีเงินเดือน",
	"sidebar.hr_department": "แผนก",
	"sidebar.hr_division": "หน่วยงาน",
	"sidebar.user" : "ผู้ใช้งานระบบ",
	"sidebar.supplier": "ซัพพลายเออร์",
	"sidebar.customer": "ลูกค้า",
	"sidebar.store": "ร้านค้า",
	"sidebar.contact": "รายชื่อผู้ติดต่อ",
	"sidebar.contact_customer": "รายชื่อลูกค้า",
	"sidebar.contact_customershop": "รายชื่อร้านค้า",
	"sidebar.contact_supplier": "รายชื่อผู้จำหน่าย",
	"sidebar.contact_person": "ผู้ติดต่อรายบุคคล",
	"sidebar.contact_member": "ลูกค้า",
	"sidebar.contact_shop_type": "Shop Type",
	"sidebar.customer_import": "Import Customer",
	"sidebar.location" : "สถานที่",
	"sidebar.location_shipto" : "สถานที่ส่งสินค้า",
	"sidebar.pm": "Project",
	"sidebar.pm_project": "โครงการ",
	"sidebar.pm_assignment": "แผนงานมอบหมาย",
	"sidebar.pm_do": "ใบส่งมอบงาน",
	"sidebar.stock": "คลังสินค้า",
	"sidebar.stock_product": "สต็อกสินค้า",
	"sidebar.stock_category": "หมวดหมู่สินค้า",
	"sidebar.stock_sort": "ประเภทสินค้า",
	"sidebar.warehouse" : "คลังสินค้า",
	"sidebar.warehouse_location": "คลังสินค้า",
	"sidebar.warehouse_loc": "คลังเก็บของ",
	"sidebar.warehouse_unit": "หน่วยสินค้า",
	"sidebar.warehouse_PRD_stock" : "สต็อกสินค้า",
	"sidebar.warehouse_PRD_stock_barcode" : "พิมพ์บาร์โค้ดสินค้า",
	"sidebar.warehouse_AST_stock": "สต็อกอุปกรณ์",
	"sidebar.warehouse_SPY_stock": "สต็อกวัสดุ",
	"sidebar.warehouse_goodstransfer": "ใบโอนสินค้า",
	"sidebar.warehouse_goodsrequest": "ใบเบิกสินค้า",
	"sidebar.warehouse_goodsreturn": "ใบรับคืนสินค้า (GR)",
	"sidebar.warehouse_goodsreceipt": "ใบรับสินค้า",
	"sidebar.warehouse_goodsadjust": "ปรับปรุงเพิ่มลดสินค้า",
	"sidebar.warehouse_orderapprove_list": "ยืนยัน Order",
	"sidebar.warehouse_picking_list": "Picking List",
	"sidebar.warehouse_pickingconfirm_list": "Confirm Picking List",
	"sidebar.warehouse_invoice_list": "ออก Invoice",
	"sidebar.warehouse_reprint_invoice": "พิมพ์ใบ Invoice (Re-print)",
	"sidebar.product" : "สินค้า",
	"sidebar.product_PRD_setting" : "ตั้งค่าสินค้า",
	"sidebar.product_PRD" : "รายการสินค้า",
	"sidebar.product_PRDConstruction" : "รายการสินค้า",
	"sidebar.product_PRD_uom" : "รายการสินค้า (UoM)",
	"sidebar.product_PRD_good" : "ประเภทสินค้า",
	"sidebar.product_PRD_category" : "หมวดหมู่สินค้า",
	"sidebar.product_PRD_type" : "หมวดหมู่สินค้าย่อย",
	"sidebar.product_PRD_brand" : "แบรนด์สินค้า",
	"sidebar.product_PRD_model" : "รุ่นสินค้า",
	"sidebar.PRD_stock_import": "Import รายการสินค้า",
	"sidebar.PRDuom_stock_import": "Import สินค้า Uom",
	"sidebar.PRDentry_stock_import": "Import ตั้งสต็อกสินค้า",
	"sidebar.sale_visit_import": "Import Visit Plan",
	"sidebar.asset_asm_supplies": "อุปกรณ์และวัสดุ",
	"sidebar.asset_AST": "รายการอุปกรณ์",
	"sidebar.asset_AST_good": "ประเภทอุปกรณ์",
	"sidebar.asset_AST_category": "หมวดหมู่อุปกรณ์",
	"sidebar.asset_AST_type": "หมวดหมู่อุปกรณ์ย่อย",
	"sidebar.asset_SPY_stock": "สต็อกวัสดุสิ้นเปลือง",
	"sidebar.asset_SPY": "รายการวัสดุ",
	"sidebar.asset_SPY_good": "ประเภทวัสดุ",
	"sidebar.asset_SPY_category": "หมวดหมู่วัสดุ",
	"sidebar.asset_SPY_type": "หมวดหมู่วัสดุย่อย",
	"sidebar.settings_company": "ตั้งค่าองค์กร",
	"sidebar.settings_sale_area": "เขตการขาย",
	"sidebar.settings_department": "แผนก",
	"sidebar.settings_division": "หน่วยงาน",
	"sidebar.settings_coupon": "คูปอง",
	"sidebar.settings_payment": "ช่องทางการชำระ",
	"sidebar.settings_branch" : "สาขา",
	"sidebar.account": "บัญชี",
	"sidebar.account_invoice": "ใบแจ้งหนี้",
	"sidebar.account_invoicebill": "ใบวางบิล/ใบแจ้งหนี้",
	"sidebar.account_receipttaxinvoice": "ใบกำกับภาษี/ใบเสร็จรับเงิน",
	"sidebar.account_receipt": "ใบเสร็จรับเงิน",
	"sidebar.account_taxinvoice": "ใบกำกับภาษี",
	"sidebar.account_collection": "Collection",
	"sidebar.account_invoicecollection": "ใบแจ้งหนี้",
	"sidebar.account_billcollection": "ใบวางบิล",
	"sidebar.operation": "แบบฟอร์มเอกสาร",
	"sidebar.operation_pr": "ใบขอซื้อ",
	"sidebar.operation_eqr": "ใบเบิกเครื่องมือ",
	"sidebar.operation_pdr": "ใบเบิกสินค้า",
	"sidebar.form_ot": "ใบเบิกค่าล่วงเวลา (OT)",
	"sidebar.form_leave": "ใบลา (Leave Form)",
	"sidebar.services": "งานบริการ",
	"sidebar.services_air": "รายการแจ้งซ่อม",
	"sidebar.services_calendar": "ปฏิทินนัดหมาย",
	"sidebar.services_deliver_doc": "หนังสือส่งมอบงาน",
	"sidebar.filemanager": "File Manager",
	"sidebar.filemanager_file_master": "Master File",
	"sidebar.report": "รายงาน",
	"sidebar.report.master": "ข้อมูล Master",
	"sidebar.report.master_customer": "Customer Master",
	"sidebar.report.sale": "การขาย",
	"sidebar.report.sale_so_detail": "ใบสั่งขายสินค้า",
	"sidebar.report.sale_by_brand": "สินค้าขายตามแบรนด์ (ไวตามิ้ลค์)",
	"sidebar.report.wms": "คลังสินค้า",
	"sidebar.report.wms_stock_balance": "สต็อกสินค้าคงเหลือ",
	"sidebar.report.wms_stock_prepicking": "Pre-Picking List",
	"sidebar.report.wms_stock_card": "Stock card",
	"sidebar.report.wms_stock_receipt": "Stock receipt by supplier",
	"sidebar.report.wms_stock_movement": "Stock movement",
	"sidebar.report.wms_stock_transfer": "Stock transfer in-out",
	"sidebar.report.wms_stock_adjust": "Stock adjust in-out",
	"sidebar.report.wms_stock_return": "Stock return",
	"sidebar.report.wms_stock_cover": "Stock cover day + inventory alert",
	"sidebar.report.wms_stock_slow": "Stock Slow to non-movement",
	"sidebar.report.wms_stock_picking": "สรุปใบหยิบสินค้า(โหลดสินค้า)",
	"sidebar.report.wms_stock_checking": "ใบตรวจนับสินค้า",
	"sidebar.report.promotion": "โปรโมชั่น",
	"sidebar.report.promotion_monthly_setting": "Monthly promotion setting",
	"sidebar.report.promotion_summary": "สรุปรายการโปรโมชั่น",
	"sidebar.report.promotion_reward_by_shop": "คูปอง แต้ม คงเหลือ รายร้านค้า",
	"sidebar.report.promotion_reward_by_sale": "ของแถม ส่วนลด คูปอง แต้ม คงเหลือ รายพนักงานขาย",
	"sidebar.report.promotion_by_brand": "ยอดโปรโมชั่นตามแบรนด์ (ไวตามิ้ลค์)",
	"sidebar.report.promotion_by_code": "สรุปรายการโปรโมชั่นตามรหัส",
	"sidebar.report.promotion_by_product": "สรุปรายการโปรโมชั่นตามสินค้า",
	"sidebar.report.promotion_by_customer": "สรุปรายการโปรโมชั่นตามร้านค้า",
	"sidebar.report.visit": "Visit",
	"sidebar.report.visit_summary_by_person": "สรุปการเยี่ยมร้านค้า รายพนักงานขาย",
	"sidebar.report.visit_detail": "การเยี่ยมร้านค้า",
	"sidebar.report.visit_customer_list": "Customer list",
	"sidebar.report.visit_open_customer": "เปิดร้านค้าใหม่",
	"sidebar.report.pos_sale": "การขาย",
	"sidebar.report.pos_sale_se": "สรุปบิลขาย",
	"sidebar.report.pos_sale_se_detail": "รายละเอียดสินค้าขาย",
	"sidebar.report.pos_sale_se_summary_daily": "สรุปยอดขายประจำวัน",
	"sidebar.report.pos_sale_se_daily": "สรุปรายละเอียดยอดขายประจำวัน",
	"sidebar.report.pos_promotion": "โปรโมชั่น",
	"sidebar.report.pos_promotion_monthly": "รายการโปรโมชั่นประจำเดือน",
	"sidebar.report.pos_promotion_summary_sale": "สรุปรายการโปรโมชั่น (ขายออก)",
	"sidebar.report.pos_stock": "คลังสินค้า",
	"sidebar.report.pos_stock_cost_of_good": "แสดงต้นทุนสินค้า",
	"sidebar.report.pos_stock_balance": "สต็อคคงเหลือ",
	"sidebar.report.pos_stock_request": "การเบิกสินค้า",
	"sidebar.report.pos_stock_purchase": "การสั่งซื้อสินค้า",
	"sidebar.report.pos_stock_in": "การรับสินค้าเข้า",
	"sidebar.report.pos_stock_adjustment": "การปรับปรุงรายการสินค้า",
	"sidebar.report.pos_stock_activity": "ความเคลื่อนไหวสินค้า",
	"sidebar.report.pos_stock_count": "Stock Count",
	"sidebar.report.pos_account": "บัญชี",
	"sidebar.report.pos_account_summary_outstanding_recv": "สรุปลูกหนี้ค้างชำระ",
	"sidebar.report.pos_account_debtor": "รายละเอียดประวัติลูกหนี้ค้างชำระและโปรสะสม",
	"sidebar.report.pos_account_sales_tax": "ภาษีขาย",

	"erp.doc_no" : "เลขที่เอกสาร",
	"erp.ref_doc_no" : "เอกสารอ้างอิง",
	"erp.project" : "โครงการ",
	"erp.doc_date" : "วันที่",
	"erp.contact" : "ผู้ติดต่อ",
	"erp.contact_phone" : "เบอร์โทรผู้ติดต่อ",
	"erp.total_amount" : "จำนวนเงิน",
	"erp.discount_amount" : "ส่วนลด",
	"erp.pdiscount_amount" : "ส่วนลดโปรโมชั่น",
	"erp.pcdiscount_amount" : "ส่วนลดแต้มและคูปอง",
	"erp.value_amount" : "มูลค่า",
	"erp.vat_amount" : "ภาษีมูลค่าเพิ่ม (7%)",
	"erp.exvat_amount" : "ยกเว้นภาษี",
	"erp.net_amount" : "รวมทั้งสิ้น",
	"erp.net_amount2" : "ยอดเงิน",
	"erp.vat" : "ภาษีมูลค่าเพิ่ม",
	"erp.item_no": "ลำดับ",
	"erp.item_cno" : "#",
	"erp.item_id" : "รหัส",
	"erp.item_code" : "รหัสสินค้า",
	"erp.item_barcode" : "Barcode",
	"erp.item_name" : "รายการ",
	"erp.item_packing" : "ขนาดบรรจุ",
	"erp.item_num" : "จำนวน",
	"erp.item_unit" : "หน่วย",
	"erp.item_unit_price" : "ราคา/หน่วย",
	"erp.item_unit_disc" : "ส่วนลด/หน่วย",
	"erp.item_disc" : "ส่วนลด",
	"erp.item_amount" : "จำนวนเงิน",
	"erp.payment_condition": "รายละเอียดเงื่อนไขการชำระเงิน",
	"erp.headoffice": "สำนักงานใหญ่",
	"erp.docviewtype" : "ประเภทเอกสาร",
	"erp.docprinttype": "ต้นฉบับ/สำเนา",
	"erp.docprinttype.original" : "ต้นฉบับ (Original)",
	"erp.docprinttype.copy" : "สำเนา (Copy)",
	"erp.docformtype" : "ฟอร์มเอกสาร",
	"erp.document_option" : "เงื่อนไขเอกสาร",
	"erp.doc_option" : "เอกสาร",
	"erp.docvatoption": "VAT Option",

	"dashboard.main.order_status" : "สถานะคำสั่งซื้อ",
	"dashboard.main.order_status.Draft" : "เปิดใหม่",
	"dashboard.main.order_status.Signed" : "ยืนยัน",
	"dashboard.main.order_status.Cancelled" : "ยกเลิก",

	"contact.title_additional":"(เพิ่มเติม)",
	"contact.profile_info" : "ข้อมูลทั่วไป",
	"contact.member_info" : "ข้อมูลสมาชิก",
	"contact.profile_attach": "เอกสารแนบ",
	"contact.profile_status": "สถานะรับงาน",
	"contact.attach_profile": "รูปภาพ",
	"contact.attach_card": "สำเนาบัตรประชาชน",
	"contact.attach_office1": "หน้าร้าน 1",
	"contact.attach_office2": "หน้าร้าน 2",
	"contact.attach_company": "เอกสาร ภพ.20",
	"contact.last_updated" : "อัพเดทล่าสุด",
	"contact.address_delivery" : "ที่อยู่จัดส่งเอกสาร",
	"contact.address" : "ที่อยู่หลัก (ใบกำกับ)",
	"contact.address_no" : "เลขที่อยู่/อาคาร/หมู่บ้าน/ถนน/ซอย",
	"contact.grade" : "เกรด",
	"contact.lat" : "ละติจูด",
	"contact.lng" : "ลองจิจูด",
	"contact.region" : "เขตตำบล",
	"contact.zipcode" : "รหัสไปรษณีย์",
	"contact.contact" : "ข้อมูลผู้ติดต่อหลัก",
	"contact.contact_name": "ชื่อผู้ติดต่อหลัก",
	"contact.contact_phone": "เบอร์โทร",
	"contact.vehicle": "ข้อมูลรถที่ขับขี่",
	"contact.vehicle_no": "เบอร์รถ",
	"contact.owner": "ผู้ดูแล",
	"contact.phone" : "เบอร์โทร",
	"contact.sex":"เพศ",
	"contact.birthdate":"วันเกิด",
	"contact.marriage":"สถานะคู่ครอง",
	"contact.fax" : "เบอร์แฟกซ์",
	"contact.email" : "อีเมลล์",
	"contact.line" : "Line ID",
	"contact.website" : "เว็บไซด์",
	"contact.company_name" : "ชื่อบริษัท",
	"contact.nickname" : "ชื่อเล่น",
	"contact.education":"การศึกษา",
	"contact.abbrname" : "ชื่อทั่วไป",
	"contact.credit" : "เครดิต",
	"contact.credit_amount" : "วงเงินเครดิต",
	"contact.branch" : "สาขา",
	"contact.branch_hint" : "(สำนักงานใหญ่)",
	"contact.company_ref" : "องค์กร",
	"contact.remark" : "หมายเหตุ",
	"contact.comment": "รายละเอียดการพิจารณา",
	"contact.taxno" : "เลขผู้เสียภาษี",
	"contact.cardno" : "เลขบัตรประชาชน",
	"contact.card_expire": "หมดอายุ",
	"contact.bank_info" : "ข้อมูลบัญชีธนาคารหลัก",
	"contact.bank" : "ธนาคาร",
	"contact.bank_account" : "เลขที่บัญชี",
	"contact.bank_branch" : "สาขา",
	"contact.bank_acc_type" : "ประเภทบัญชี",
	"contact.account_name" : "ชื่อบัญชี",
	"contact.account" : "ข้อมูลทางบัญชี",
	"contact.account_no": "รหัสบัญชี",
	"contact.doc_type" : "ประเภทการติดต่อ",
	"contact.doc_date": "วันที่ลงทะเบียน",
	"contact.sale_area": "เขตการขาย",
	"contact.point":"แต้มสะสม",
	"contact.holiday":"วันหยุด",
	"contact.open":"Open",
	"contact.close":"Close",
	"contact.province": "จังหวัด",
	"contact.status.D" : "เอกสารใหม่",
	"contact.status.S" : "ส่งข้อมูล",
	"contact.status.V" : "ยืนยันข้อมูล",
	"contact.status.R" : "ไม่อนุมัติ",
	"contact.status.A1" : "อนุมัติ",
	"contact.status.A" : "Active",
	"contact.status.N" : "In-Active",
	"contact.status.C" : "ยกเลิก",

	"company.title": "ร้านค้า POS",
	"company.code" : "รหัสร้านค้า",
	"company.name": "ชื่อร้านค้า",
	"company.account_prefix_code":"รหัสสาขา",
	"company.profile_info" : "ข้อมูลกิจการ",
	"company.other_info" : "การตั้งค่าอื่นๆ",
	"company.nameth": "ชื่อตามบิล",
	"company.nameen": "ชื่อตามบิล (EN)",
	"company.contactth": "ข้อมูลหัวบิล",
	"company.contacten": "ข้อมูลหัวบิล (EN)",
	"company.taxno": "เลขผู้เสียภาษี",
	"company.warehouse": "คลังร้านค้า",
	"company.point_use": "การแลกแต้มเป็นส่วนลด",
	"company.point_use_hint": "แต้ม แทนเงินสด 1 บาท",
	"company.photo_logo": "รูปภาพโลโก้ร้านค้า",
	"company.photo_qrpayment": "QR Code รับจ่ายเงิน",
	"company.owner": "ที่ปรึกษาขาย",
	"company.owner_wh": "คลังที่ปรึกษาขาย",
	"company.shop": "ประเภท POS",
	"company.status.A": "Active",
	"company.status.N": "In-active",

	"shopsetting.title": "ตั้งค่าร้านค้า",
	"shopsetting.code" : "รหัสร้านค้า",
	"shopsetting.name": "ชื่อกิจการ",
	"shopsetting.profile_info" : "ข้อมูลกิจการ",
	"shopsetting.bill_info" : "ข้อมูลออกบิลและใบกำกับ",
	"shopsetting.other_info" : "การตั้งค่าอื่นๆ",
	"shopsetting.account_prefix_code":"รหัสสาขา",
	"shopsetting.owner":"ที่ปรึกษาขาย",
	"shopsetting.shop": "ประเภท POS",
	"shopsetting.nameth": "ชื่อร้าน",
	"shopsetting.nameen": "ชื่อตามบิล (EN)",
	"shopsetting.contactth": "หัวบิล (ใบกำกับภาษีแบบเต็มรูป)",
	"shopsetting.contacten": "ข้อมูลหัวบิล (EN)",
	"shopsetting.phone": "เบอร์โทร",
	"shopsetting.taxno": "เลขประจำตัวผู้เสียภาษี",
	"shopsetting.warehouse": "คลังหลักของร้าน",
	"shopsetting.bill_top_remark": "หัวบิล (แบบย่อ)",
	"shopsetting.bill_remark": "ท้ายบิล (แบบย่อ)",
	"shopsetting.point_use": "การแลกแต้มเป็นส่วนลด",
	"shopsetting.point_use_hint": "แต้ม แทนเงินสด 1 บาท",
	"shopsetting.photo_logo": "รูปภาพโลโก้ร้านค้า",
	"shopsetting.photo_qrpayment": "QR Code รับจ่ายเงิน",

	"orgsetting.title": "ตั้งค่าองค์กร",
	"orgsetting.code" : "รหัสบริษัท",
	"orgsetting.name": "ชื่อบริษัท",
	"orgsetting.profile_info" : "ข้อมูลกิจการ",
	"orgsetting.other_info" : "การตั้งค่าอื่นๆ",
	"orgsetting.nameth": "ชื่อตามเอกสาร",
	"orgsetting.nameen": "ชื่อตามเอกสาร (EN)",
	"orgsetting.contactth": "ข้อมูลหัวเอกสาร",
	"orgsetting.contacten": "ข้อมูลหัวเอกสาร (EN)",
	"orgsetting.taxno": "เลขประจำตัวผู้เสียภาษี",
	"orgsetting.warehouse": "คลังร้านค้า",
	"orgsetting.point_use": "การแลกแต้มเป็นส่วนลด",
	"orgsetting.point_use_hint": "แต้ม แทนเงินสด 1 บาท",
	"orgsetting.photo_logo": "รูปภาพโลโก้บริษัท",

	"customer.title" : "ลูกค้า",
	"customer.name" : "ชื่อกิจการ",
	"customer.doc_no" : "รหัสลูกค้า",
	"customer.contact_type" : "Shop Type",
	"supplier.title" : "ผู้จำหน่าย (Supplier)",
	"supplier.name" : "ชื่อกิจการ",
	"supplier.doc_no" : "รหัสอ้างอิง",
	"supplier.contact_type" : "ประเภทผู้จำหน่าย",
	"person.title" : "ผู้ติดต่อ",
	"person.name" : "ชื่อผู้ติดต่อ",
	"person.doc_no" : "รหัสผู้ติดต่อ",
	"person.contact_type" : "ประเภทผู้ติดต่อ",
	"vendor.title" : "ผู้ให้บริการ (Vendor)",
	"vendor.name" : "ชื่อองค์กร",
	"vendor.doc_no" : "รหัสอ้างอิง",
	"vendor.contact_type" : "ประเภทองค์กร",
	"trucker.title" : "ทะเบียนผู้ขับรถบรรทุก",
	"trucker.name" : "ชื่อ-สกุล",
	"trucker.doc_no" : "รหัสผู้ขับ",
	"trucker.contact_type" : "ประเภทผู้ขับ",
	"member.title" : "ลูกค้า",
	"member.name" : "ชื่อลูกค้า",
	"member.doc_no" : "รหัสลูกค้า",
	"member.contact_type" : "ประเภทลูกค้า",
	"member.member" : "เป็นสมาชิก",
	"member.member_type" : "ประเภทสมาชิก",

	"shipto.title" : "สถานที่ส่งสินค้า",
	"shipto.name" : "ชื่อสถานที่",
	"shipto.doc_no" : "รหัสสถานที่",
	"shipto.location_type" : "ประเภทสถานที่",
	"shipto.ref_id" : "องค์กร/บริษัท/ลูกค้า",
	"shipto.address_no" : "เลขที่อยู่/อาคาร/หมู่บ้าน/ถนน/ซอย",
	"shipto.lat" : "ละติจูด",
	"shipto.lng" : "ลองจิจูด",
	"shipto.region" : "เขตตำบล",
	"shipto.zipcode" : "รหัสไปรษณีย์",
	"shipto.radius" : "รัศมี (เมตร)",

	"PO.title" : "ใบสั่งซื้อ",
	"PO.title2" : "Purchase Order",
	"PO.supplier" : "ผู้จัดจำหน่าย",
	"PO.owner_person" : "ผู้สั่งซื้อ",
	"PO.ref_doc_no": "เลขที่อ้างอิง",
	"PO.location": "คลังสินค้า",
	"PO.deliver_date" : "วันกำหนดส่งสินค้า",
	"PO.total_text": "รวมทั้งสิ้น",
	"PO.total_text_unit": "รายการ",
	"PO.payment_term" : "เครดิตการชำระเงิน",
	"PO.value_amount" : "มูลค่าสินค้า",
	"PO.totald_amount": "ยอดรวมหลังหักส่วนลด",
	"PO.net_amount": "ยอดรวมสุทธิ",
	"PO.proposed_by" : "ผู้สั่งซื้อ",
	"PO.authorized_by" : "ผู้อนุมัติการสั่งซื้อ",
	"PO.status.Signed": "ยืนยันการสั่งซื้อ",
	"PO.status.Cancelled": "ยกเลิก",

	"QT.title" : "ใบเสนอราคา",
	"QT.title2" : "Quotation",
	"QT.customer" : "ชื่อลูกค้า",
	"QT.sale_person" : "ผู้ขาย",
	"QT.deliver_date" : "วันกำหนดส่งสินค้า",
	"QT.total_text": "รวมทั้งสิ้น",
	"QT.total_text_unit": "รายการ",
	"QT.payment_term" : "เครดิตการชำระเงิน",
	"QT.value_amount" : "จำนวนเงินหลังหักส่วนลด",
	"QT.proposed_by" : "ผู้เสนอราคา",
	"QT.authorized_by" : "ผู้อนุมัติการสั่งซื้อ (ลูกค้า)",
	"QT.std_date" : "ยืนราคาถึงวันที่",

	"SO.title" : "ใบสั่งขาย",
	"SO.title2" : "Sale Order",
	"SO.customer" : "ชื่อร้านค้า",
	"SO.inv_no" : "เลขที่ Invoice",
	"SO.inv_date" : "วันที่ Invoice",
	"SO.sale_person" : "ผู้ขาย",
	"SO.payment_term" : "เครดิตการชำระเงิน",
	"SO.value_amount" : "มูลค่าสินค้า",
	"SO.totald_amount": "ยอดรวมหลังหักส่วนลด",
	"SO.net_amount": "ยอดรวมสุทธิ",
	"SO.proposed_by" : "ผู้เสนอราคา",
	"SO.authorized_by" : "ผู้อนุมัติการสั่งซื้อ (ลูกค้า)",
	"SO.order_date" : "วันที่สั่งซื้อ",
	"SO.deliver_date" : "วันที่ส่งสินค้า",
	"SO.shipto":"ส่งสินค้าที่",
	"SO.shipto_address":"ที่อยู่จัดส่ง",
	"SO.sale_area": "เขตการขาย",
	"SO.total_text": "รวมทั้งสิ้น",
	"SO.total_text_unit": "รายการ",
	"SO.coupon_used": "ใช้คูปองส่วนลด",
	"SO.point_used": "ใช้แต้มเป็นส่วนลด",
	"SO.status.Draft": "เรียกคืนเอกสาร",
	"SO.status.Signed": "อนุมัติ",
	"SO.status.Cancelled": "ยกเลิก",

	"PS.title" : "ใบสั่งซื้อ",
	"PS.title2" : "Purchase Order",
	"PS.customer" : "ชื่อร้านค้า",
	"PS.sale_person" : "ที่ปรึกษาขาย",
	"PS.pos_id" : "POS ID",
	"PS.payment_term" : "เครดิตการชำระเงิน",
	"PS.value_amount" : "มูลค่าสินค้า",
	"PS.totald_amount": "ยอดรวมหลังหักส่วนลด",
	"PS.net_amount": "ยอดรวมสุทธิ",
	"PS.proposed_by" : "ผู้สั่งซื้อ",
	"PS.authorized_by" : "ผู้อนุมัติการสั่งซื้อ",
	"PS.order_date" : "วันที่สั่งซื้อ",
	"PS.deliver_date" : "วันที่ส่งสินค้า",
	"PS.shipto":"ส่งสินค้าที่",
	"PS.shipto_address":"ที่อยู่จัดส่ง",
	"PS.sale_area": "เขตการขาย",
	"PS.total_text": "รวมทั้งสิ้น",
	"PS.total_text_unit": "รายการ",
	"PS.coupon_used": "ใช้คูปองส่วนลด",
	"PS.point_used": "ใช้แต้มเป็นส่วนลด",
	"PS.status.Draft": "รอยืนยันคำสั่งซื้อ",
	"PS.status.Signed": "ยืนยันคำสั่งซื้อ",
	"PS.status.Cancelled": "ยกเลิก",

	"SE.title" : "รายการขายสินค้า",
	"SE.title2" : "Sale Entry",
	"SE.customer" : "ชื่อลูกค้า",
	"SE.sale_person" : "ผู้ขาย",
	"SE.value_amount" : "มูลค่าสินค้า",
	"SE.totald_amount": "ยอดรวมหลังหักส่วนลด",
	"SE.net_amount": "ยอดรวมสุทธิ",
	"SE.order_date" : "วันที่ขาย",
	"SE.total_text": "รวมทั้งสิ้น",
	"SE.total_text_unit": "รายการ",
	"SE.coupon_used": "ใช้คูปองส่วนลด",
	"SE.point_used": "ใช้แต้มเป็นส่วนลด",
	"SE.full_tax_invoice": "ใบกำกับภาษีเต็มรูปแบบ",
	"SE.status.Cancelled": "ยกเลิก",

	"PE.title" : "ใบกำกับภาษี/ใบเสร็จรับเงิน",
	"PE.title2" : "Invoice/Receipt",
	"PE.customer" : "ชื่อลูกค้า",
	"PE.sale_person" : "ผู้ขาย",
	"PE.value_amount" : "มูลค่าสินค้า",
	"PE.totald_amount": "ยอดรวมหลังหักส่วนลด",
	"PE.net_amount": "ยอดรวมสุทธิ",
	"PE.order_date" : "วันที่ขาย",
	"PE.total_text": "รวมทั้งสิ้น",
	"PE.total_text_unit": "รายการ",
	"PE.coupon_used": "ใช้คูปองส่วนลด",
	"PE.point_used": "ใช้แต้มเป็นส่วนลด",
	"PE.make_by": "ผู้จัดทำ",
	"PE.got_by": "ผู้รับสินค้า",
	"PE.new_customer": "เพิ่มลูกค้าใหม่",
	"PE.status.Cancelled": "ยกเลิก",

	"RI.title" : "ใบรับสินค้า",
	"RI.title2" : "Goods Receipt",
	"RI.supplier" : "ผู้จัดจำหน่าย",
	"RI.location" : "นำเข้าคลัง",
	"RI.total_text": "รวมทั้งสิ้น",
	"RI.total_text_unit": "รายการ",
	"RI.value_amount" : "มูลค่าสินค้า",
	"RI.totald_amount": "ยอดรวมหลังหักส่วนลด",
	"RI.net_amount": "ยอดรวมสุทธิ",
	"RI.receipt_date" : "วันที่รับสินค้า",
	"RI.receipt_person": "ผู้รับสินค้า",
	"RI.deliver_date": "วันกำหนดส่ง",
	"RI.ref_doc_no": "เลขที่สั่งซื้ออ้างอิง",
	"RI.proposed_by" : "ผู้รับสินค้า",
	"RI.authorized_by" : "ผู้ตรวจสอบ",
	"RI.status.Draft": "ดำเนินการตรวจรับสินค้า",
	"RI.status.Signed": "ตรวจรับสินค้าสมบูรณ์",
	"RI.status.Cancelled": "ยกเลิก",

	"RP.title" : "ใบรับสินค้า",
	"RP.title2" : "Goods Receipt",
	"RP.supplier" : "ผู้จัดจำหน่าย",
	"RP.location" : "นำเข้าคลัง",
	"RP.total_text": "รวมทั้งสิ้น",
	"RP.total_text_unit": "รายการ",
	"RP.value_amount" : "มูลค่าสินค้า",
	"RP.totald_amount": "ยอดรวมหลังหักส่วนลด",
	"RP.net_amount": "ยอดรวมสุทธิ",
	"RP.receipt_date" : "วันที่รับสินค้า",
	"RP.receipt_person": "ผู้รับสินค้า",
	"RP.deliver_date": "วันกำหนดส่ง",
	"RP.ref_doc_no": "เลขที่สั่งซื้ออ้างอิง",
	"RP.proposed_by" : "ผู้รับสินค้า",
	"RP.authorized_by" : "ผู้ตรวจสอบ",
	"RP.status.Draft": "ดำเนินการตรวจรับสินค้า",
	"RP.status.Signed": "ตรวจรับสินค้าสมบูรณ์",
	"RP.status.Cancelled": "ยกเลิก",

	"RT.title" : "ใบขอลดหนี้/ส่งคืนสินค้า",
	"RT.title2" : "Goods Return (AP)",
	"RT.supplier" : "ผู้จัดจำหน่าย",
	"RT.location" : "คลังสินค้า",
	"RT.bill_no": "อ้างถึงบิลเลขที่",
	"RT.bill_date": "บิลลงวันที่",
	"RT.total_text": "รวมทั้งสิ้น",
	"RT.total_text_unit": "รายการ",
	"RT.total_amount": "รวมเป็นเงิน",
	"RT.discount_amount": "หัก ส่วนลด",
	"RT.totald_amount": "จำนวนเงินรวมทั้งสิ้น",
	"RT.ref_amount": "มูลค่าใบกำกับเดิม",
	"RT.value_amount" : "มูลค่าที่ถูกต้อง",
	"RT.diff_amount" : "ผลต่าง (มูลค่าคืนสินค้า)",
	"RT.exvat_amount": "มูลค่าคืนสินค้ายกเว้นภาษี",
	"RT.net_amount": "รวมมูลค่าสุทธิ",
	"RT.doc_date" : "วันที่เอกสาร",
	"RT.warehouse" : "คืนจากคลัง",
	"RT.person": "ผู้ส่งคืนสินค้า",
	"RT.ref_doc_no": "อ้างอิงใบรับสินค้า",
	"RT.receipt_msg" : "ได้รับสินค้าข้างต้นคืนเรียบร้อยแล้ว",
	"RT.owner_msg" : "ได้ส่งสินค้าข้างต้นคืนเรียบร้อยแล้ว",
	"RT.receiver" : "ลายมือชื่อผู้รับคืน",
	"RT.owner" : "ลายมือชื่อผู้ส่งคืน",
	"RT.approver" : "ผู้อนุมัติ",
	"RT.status.Draft": "ดำเนินการ",
	"RT.status.Signed": "ยืนยันการส่งคืนสินค้า",
	"RT.status.Cancelled": "ยกเลิก",

	"AJ.title" : "ใบปรับปรุงเพิ่มลดสินค้า",
	"AJ.title2" : "Goods Adjust",
	"AJ.supplier" : "ผู้จัดจำหน่าย",
	"AJ.warehouse" : "คลังสินค้า",
	"AJ.total_text": "รวมทั้งสิ้น",
	"AJ.total_text_unit": "รายการ",
	"AJ.value_amount" : "มูลค่าสินค้า",
	"AJ.totald_amount": "ยอดรวมหลังหักส่วนลด",
	"AJ.net_amount": "ยอดรวมสุทธิ",
	"AJ.doc_date" : "วันที่",
	"AJ.person": "ผู้ดำเนินการ",
	"AJ.deliver_date": "วันกำหนดส่ง",
	"AJ.item_balance": "คงเหลือ",
	"AJ.item_add": "เพิ่มเข้า",
	"AJ.item_sub": "ลดออก",
	"AJ.item_num": "สต็อกจริง",
	"AJ.item_remark": "หมายเหตุ",
	"AJ.proposed_by" : "ผู้ดำเนินการ",
	"AJ.authorized_by" : "ผู้อนุมัติ",
	"AJ.status.Signed": "ยืนยันดำเนินการ",
	"AJ.status.Cancelled": "ยกเลิก",

	"PQ.title" : "ใบเบิกสินค้า",
	"PQ.title2" : "Goods Request",
	"PQ.supplier" : "บริษัท",
	"PQ.warehouse": "จากคลัง",
	"PQ.location" : "นำเข้าคลัง",
	"PQ.total_text": "รวมทั้งสิ้น",
	"PQ.total_text_unit": "รายการ",
	"PQ.doc_date" : "วันที่เบิกสินค้า",
	"PQ.person": "ผู้เบิกสินค้า",
	"PQ.deliver_date": "วันกำหนดส่ง",
	"PQ.proposed_by" : "ผู้เบิกสินค้า",
	"PQ.authorized_by" : "ผู้ตรวจสอบ",
	"PQ.status.Draft": "รอยืนยันกาาขอเบิก",
	"PQ.status.Signed": "ยืนยันการขอเบิก",
	"PQ.status.Cancelled": "ยกเลิก",

	"RQ.title" : "ใบเบิกสินค้า",
	"RQ.title2" : "Goods Request",
	"RQ.customer" : "ร้านค้า",
	"RQ.project": "ประเภทการเบิก",
	"RQ.warehouse" : "เบิกสินค้าจากคลัง",
	"RQ.ship_to" : "เบิกสินค้าไปที่คลัง",
	"RQ.total_text": "รวมทั้งสิ้น",
	"RQ.total_text_unit": "รายการ",
	"RQ.doc_date" : "วันที่เบิกสินค้า",
	"RQ.person": "ผู้ขอเบิกสินค้า",
	"RQ.deliver_date": "วันกำหนดส่ง",
	"RQ.proposed_by" : "ผู้เบิกสินค้า",
	"RQ.authorized_by" : "ผู้ตรวจสอบ",
	"RQ.status.Signed": "ยืนยันการขอเบิก",
	"RQ.status.Cancelled": "ยกเลิก",

	"TS.title" : "ใบโอนสินค้า",
	"TS.title2" : "Stock Transfer",
	"TS.customer" : "ร้านค้า",
	"TS.warehouse" : "โอนสินค้าจากคลัง",
	"TS.ship_to" : "โอนสินค้าไปยังคลัง",
	"TS.project": "ประเภทการโอนสินค้า",
	"TS.total_text": "รวมทั้งสิ้น",
	"TS.total_text_unit": "รายการ",
	"TS.doc_date" : "วันที่โอนสินค้า",
	"TS.person": "ผู้โอนสินค้า",
	"TS.proposed_by" : "ผู้โอนสินค้า",
	"TS.authorized_by" : "ผู้ตรวจสอบ",
	"TS.status.Signed": "ยืนยันการโอนสินค้า",
	"TS.status.Cancelled": "ยกเลิก",

	"GQ.title" : "ใบขอคืนสินค้า",
	"GQ.title2" : "Goods Return",
	"GQ.supplier" : "บริษัท",
	"GQ.warehouse": "ไปยังคลัง",
	"GQ.location" : "คืนจากคลัง",
	"GQ.total_text": "รวมทั้งสิ้น",
	"GQ.total_text_unit": "รายการ",
	"GQ.doc_date" : "วันที่ขอคืนสินค้า",
	"GQ.person": "ผู้คืนสินค้า",
	"GQ.deliver_date": "วันกำหนดส่งคืน",
	"GQ.ref_doc_no": "เลขที่อ้างอิง",
	"GQ.project": "เลขที่ Invoice",
	"GQ.return_reason": "เหตุผลการคืนสินค้า",
	"GQ.proposed_by" : "ผู้คืนสินค้า",
	"GQ.authorized_by" : "ผู้ตรวจสอบ",
	"GQ.status.Draft": "รอยืนยันกาาขอคืนสินค้า",
	"GQ.status.Signed": "ยืนยันการขอคืนสินค้า",
	"GQ.status.Cancelled": "ยกเลิก",

	"GR.title" : "ใบรับคืนสินค้า/ใบลดหนี้",
	"GR.title2" : "Goods Return",
	"GR.customer" : "ลูกค้า",
	"GR.warehouse" : "นำเข้าคลัง",
	"GR.total_text": "รวมทั้งสิ้น",
	"GR.total_text_unit": "รายการ",
	"GR.value_amount" : "มูลค่าสินค้า",
	"GR.totald_amount": "ยอดรวมหลังหักส่วนลด",
	"GR.net_amount": "ยอดรวมสุทธิ",
	"GR.receipt_date" : "วันที่รับสินค้า",
	"GR.receipt_person": "ผู้รับสินค้า",
	"GR.deliver_date": "วันกำหนดส่ง",
	"GR.ref_doc_no": "เลขที่อ้างอิง",
	"GR.project": "เลขที่ Invoice",
	"GR.return_reason": "เหตุผลการคืนสินค้า",
	"GR.proposed_by" : "ผู้รับสินค้า",
	"GR.authorized_by" : "ผู้ตรวจสอบ",
	"GR.status.Draft": "ดำเนินการตรวจรับสินค้า",
	"GR.status.Signed": "ตรวจรับสินค้าสมบูรณ์",
	"GR.status.Cancelled": "ยกเลิก",

	"IV.title" : "ใบแจ้งหนี้",
	"IV.title2" : "Invoice",
	"IV.title_payment": "ข้อมูลการชำระเงิน",
	"IV.title_cheque": "กรณีรับชำระด้วยเงินด้วยเช็ค",
	"IV.title_account_bank": "บันทึกเงินเข้าบัญชี",
	"IV.pay_status": "การชำระ",
	"IV.ref_doc_no" : "เลขที่อ้างอิง",
	"IV.customer" : "ลูกค้า",
	"IV.inv_date" : "วันที่เอกสาร",
	"IV.due_date" : "กำหนดชำระ",
	"IV.net_amount" : "ยอดเงิน",
	"IV.cn_amount" : "ยอดลดหนี้",
	"IV.paid_amount" : "ยอดชำระ",
	"IV.balance_amount" : "ยอดคงค้าง",
	"IV.sale_person": "ผู้ขาย",
	"IV.collection_person": "ผู้วางบิล",
	"IV.payment_term": "เครดิต",
	"IV.proposed_by" : "ผู้ออกเอกสาร",
	"IV.authorized_by" : "ผู้อนุมัติ",
	"IV.status.Signed": "ยืนยันใบแจ้งหนี้",
	"IV.status.Cancelled": "ยกเลิก",

	"BN.title" : "ใบวางบิล",
	"BN.title2" : "Billing Note",
	"BN.title_invoice": "ข้อมูลใบแจ้งหนี้",
	"BN.title_payment": "ชำระเงินโดยช่องทางอื่นๆ",
	"BN.title_cheque": "ชำระเงินโดยเช็ค",
	"BN.title_account_bank": "การชำระเงิน (เงินเข้าบัญชี)",
	"BN.bill_no": "เลขที่ใบวางบิล",
	"BN.bill_date" : "วันที่วางบิล",
	"BN.inv_no": "เลขที่เอกสาร",
	"BN.inv_date" : "วันที่เอกสาร",
	"BN.due_date": "วันที่ครบกำหนด",
	"BN.inv_amount": "ยอดเงินรวม",
	"BN.bill_amount": "ยอดเงินวางบิล",
	"BN.bill_amount2": "ยอดวางบิล",
	"BN.customer" : "ลูกค้า",
	"BN.sale_person": "ผู้ขาย",
	"BN.collect_person": "ผู้วางบิล",
	"BN.item_code": "เลขที่ Invoice",
	"BN.item_inv_date": "วันที่ Invoice",
	"BN.item_due_date": "กำหนดชำระ",
	"BN.item_inv_amt": "ยอดบิล",
	"BN.item_osd_amt": "ยอดคงค้าง",
	"BN.item_amount": "ยอดชำระ",
	"BN.payment_amount": "จำนวนเงินชำระ",
	"BN.net_amount": "ยอดหนี้รวมทั้งสิ้น",
	"BN.vat_amount": "หัก ณ ที่จ่าย",
	"BN.total_amount": "ยอดที่ต้องชำระ",
	"BN.value_amount": "ยอดชำระจริง",
	"BN.fee_amount": "ค่าธรรมเนียม",
	"BN.paid_amount": "ยอดชำระบิล",
	"BN.totald_amount": "ส่วนต่าง",
	"BN.pay_amount":"จำนวนเงิน",
	"BN.pcdiscount_amount": "ค่าธรรมเนียม",
	"BN.chq_no": "เลขที่เช็ค",
	"BN.chq_date": "ลงวันที่",
	"BN.chq_bank": "ธนาคาร",
	"BN.chq_branch": "สาขา",
	"BN.ti_date": "เงินเข้าวันที่",
	"BN.ti_bank": "เข้าบัญชี",
	"BN.reason": "เหตุผลส่วนต่าง",
	"BN.pay_by": "ชำระโดย",
	"BN.pay_type": "การชำระ",
	"BN.pay_full": "ชำระเต็ม",
	"BN.pay_part": "ชำระบางส่วน",
	"BN.pay_over": "ชำระเกิน",
	"BN.proposed_by" : "ผู้รับชำระ",
	"BN.authorized_by" : "ผู้ตรวจสอบ",
	"BN.status.Signed": "ยืนยันการรับชำระ",
	"BN.status.Cancelled": "ยกเลิก",

	"PU.title": "การใช้แต้ม",
	"PU.customer" : "ชื่อลูกค้า",
	"PU.person" : "ผู้ทำรายการ",

	"receipt.title" : "ใบเสร็จรับเงิน",
	"receipt.title2" : "Receipt",
	"receipt.customer" : "ชื่อบริษัท",
	"receipt.value_amount" : "จำนวนเงินหลังหักส่วนลด",
	"receipt.received_by" : "ผู้รับสินค้า",
	"receipt.financial_by" : "ผู้รับเงิน",
	"receipt.authorized_by" : "ผู้รับมอบอำนาจ",

	"receipttaxinvoice.title" : "ใบกำกับภาษี/ใบเสร็จรับเงิน",
	"receipttaxinvoice.title2" : "Tax Invoice/Receipt",

	"taxinvoice.title" : "ใบกำกับภาษี",
	"taxinvoice.title2" : "Tax Invoice",

	"invoice.title" : "ใบแจ้งหนี้",
	"invoice.title2" : "Invoice",
	"invoice.customer" : "ชื่อบริษัท",
	"invoice.payment_term" : "เงื่อนไขการชำระเงิน",
	"invoice.po" : "ใบสั่งซื้ออ้างอิง",
	"invoice.value_amount" : "จำนวนเงินหลังหักส่วนลด",
	"invoice.authorized_by" : "ผู้มีอำนาจลงนาม",
	"invoice.checked_by" : "ผู้ตรวจสอบ",
	"invoice.received_by" : "ผู้รับสินค้า",

	"invoicebill.title" : "ใบวางบิล/ใบแจ้งหนี้",
	"invoicebill.title2" : "Billing Note/Invoice",

	"staff.title" : "พนักงาน",
	"staff.profile" : "ข้อมูลพนักงาน",
	"staff.profile_image" : "รูปถ่าย",
	"staff.title_other": "การตั้งค่าอื่นๆ",
	"staff.card" : "บัตรประชาชน",
	"staff.code" : "รหัสพนักงาน",
	"staff.name" : "ชื่อพนักงาน",
	"staff.nickname" : "ชื่อเล่น",
	"staff.position" : "ตำแหน่ง",
	"staff.department" : "แผนก",
	"staff.division" : "หน่วยงาน",
	"staff.phone": "เบอร์โทร",
	"staff.email": "อีเมลล์",
	"staff.birthday": " วันเกิด",
	"staff.gender": "เพศ",
	"staff.card_id": "เลขบัตรประชาชน",
	"staff.line_id": "Line ID",
	"staff.role" : "User Role",
	"staff.registered_date": "วันที่เริ่มงาน",
	"staff.resigned_date": "วันที่ออกจากงาน",
	"staff.staff_status": "การจ้างงาน",
	"staff.staff_type": "ประเภทพนักงาน",
	"staff.area": "พื้นที่รับผิดชอบ",
	"staff.user_status": "สถานะผู้ใช้งานระบบ",
	"staff.password": "รหัสผ่าน",
	"staff.benefit": "ค่าตอบแทน",
	"staff.salary": "เงินเดือน",
	"staff.daily_wage": "ค่าจ้างรายวัน",
	"staff.ot_rate": "OT ปกติ",
	"staff.ot_holiday_rate": "OT วันหยุด",
	"staff.timework": "เวลาการทำงาน",
	"staff.timein": "เวลาเข้างาน",
	"staff.timeout": "เวลาเลิกงาน",
	"staff.ot_time": "เวลาเริ่ม OT",
	"staff.holiday": "วันหยุด",
	"staff.sale_area": "เขตการขาย",
	"staff.supervisor_id": "หัวหน้างาน",
	"staff.visit_target_monthly": "เป้า Visit รายเดือน",
	"staff.sale_target_monthly": "เป้ายอดขายรายเดือน",
	"staff.authorized": "สิทธิ์ในระบบ (นอกเหนือจากสิทธิ์ตาม User Role)",
	"staff.branch": "สาขา",
	"staff.wh": "คลังสินค้า",
	"staff.status.Active": "Active",
	"staff.status.Inactive": "Inactive",

	"timesheet.title" : "ตารางลงเวลางาน",
	"timesheet.title2" : "Timesheet",
	"timesheet.in" : "เวลาเข้างาน",
	"timesheet.out" : "เวลาเลิกงาน",
	"timesheet.volume" : "จำนวนผู้ปฏิบัติงาน",

	"payroll.title" : "สรุปจ่ายเงินเดือน",
	"payroll.title2" : "Payslip Summary",
	"payroll.doc_date" : "วันที่",
	"payroll.volume" : "ยอดจ่ายสุทธิ",
	"payroll.item_salary": "เงินเดือน",
	"payroll.item_day": "จำนวนวัน",
	"payroll.item_revenue": "จ่ายงวดนี้",
	"payroll.item_net_revenue": "ยอดรับเงิน",
	"payroll.item_ot": "OT",
	"payroll.item_ddvat": "หักภาษี",
	"payroll.item_ddins": "ค่าประกัน",
	"payroll.item_ddleave": "หักขาด/ลา/สาย",
	"payroll.item_ddcard": "ค่าทำบัตร",
	"payroll.item_ddother": "ค่าอื่นๆ",
	"payroll.item_payment": "ยอดจ่าย",

	"project.title": "Project",
	"project.customer" : "ลูกค้า",
	"project.doc_date" : "วันที่เริ่ม",
	"project.doc_no" : "Project No",
	"project.project" : "ชื่อโครงการ",
	"project.ref_doc_no" : "เลขที่สัญญา",
	"project.pm" : "PM",
	"project.net_amount" : "มูลค่าโครงการ",
	"project.description" : "รายละเอียดโครงการ",
	"project.payment" : "การชำระเงิน",
	"project.project_type" : "ประเภทโครงการ",
	"project.finished_date" : "วันที่สิ้นสุด",
	"project.status.Signed": "อนุมัติเริ่มโครงการ",
	"project.status.Cancelled": "ยกเลิก",
	"project.status.Closed": "ปิดโครงการ",
	"project.status.Draft": "เรียกกลับคืน",

	"assignment.title" : "แผนงานมอบหมาย",
	"assignment.title2" : "Assignment",
	"assignment.doc_date" : "วันที่เริ่ม",
	"assignment.doc_no" : "เลขที่งาน",
	"assignment.ref_doc_no" : "ชื่องาน",
	"assignment.customer" : "ลูกค้า",
	"assignment.pm" : "PM",
	"assignment.owner_id" : "Project Manager",
	"assignment.finished_date" : "วันที่เสร็จงาน",
	"assignment.volume" : "จำนวนงาน",
	"assignment.item_no": "No.",
	"assignment.item_topic": "งาน",
	"assignment.item_person": "ผู้รับผิดชอบ",
	"assignment.item_assign_date": "วันที่มอบหมาย",
	"assignment.item_due_date": "วันกำหนดเสร็จ",
	"assignment.item_result": "ผลการดำเนินการ",
	"assignment.item_due": "เวลา (วัน)",
	"assignment.status.Signed": "อนุมัติ",
	"assignment.status.Cancelled": "ยกเลิก",
	"assignment.status.Draft": "เรียกกลับคืน",

	"LVE.title" : "ใบลา (Leave Form)",
	"LVE.title_documents": "เอกสารแนบใบลา",
	"LVE.medical_certificate":"ใบรับรองแพทย์",
	"LVE.photo_desc":"เอกสารแนบ",
	"LVE.notice_to": "เรียน",
	"LVE.notice_to_hint": "หัวหน้างาน / ผู้บังคับบัญชา",
	"LVE.supervisor": "หัวหน้างานผู้อนุมัติ",
	"LVE.doc_no" : "Doc No.",
	"LVE.doc_date" : "วันที่แจ้ง",
	"LVE.doc_time" : "เวลา",
	"LVE.due_date": "วันที่ลา",
	"LVE.end_date": "ถึงวันที่",
	"LVE.end_date_hint": "กรณีลาติดต่อ",
	"LVE.days" : "จำนวนวันลา",
	"LVE.leave": "ขอลา",
	"LVE.leave_type": "ประเภทการลา",
	"LVE.reason": "เนื่องจาก",
	"LVE.contact": "ในระหว่างลาติดต่อข้าพเจ้าได้ที่",
	"LVE.contact_phone": "เบอร์โทรศัพท์",
	"LVE.created_user_name": "ข้าพเจ้า",
	"LVE.hr_name": "ฝ่ายบุคคล",
	"LVE.notice_by": "พนักงาน",
	"LVE.created_time": "เวลาที่แจ้ง",
	"LVE.comment1": "ความเห็นเพิ่มเติมของหัวหน้างาน",
	"LVE.comment2": "ความเห็นเพิ่มเติมของฝ่ายบุคคล",
	"LVE.status.Signed": "อนุมัติ",
	"LVE.status.Rejected": "ไม่อนุมัติ",
	"LVE.status.Closed": "ยืนยันอนุมัติ",
	"LVE.status.NotVerified": "ไม่อนุมัติ",
	"LVE.status.Cancelled": "ยกเลิก",

	"department.code" : "รหัสแผนก",
	"department.text" : "ชื่อแผนก",
	"divison.code" : "รหัสหน่วย",
	"divison.text" : "ชื่อหน่วย",

	"PRD.title": "สินค้า",
	"PRD.code": "รหัสสินค้า",
	"PRD.name": "ชื่อสินค้า",
	"PRD.information": "ข้อมูลสินค้า",
	"PRD.photo_main": "รูปภาพ",
	"PRD.more": "ข้อมูลสินค้าเพิ่มเติม",
	"PRD.type": "หมวดหมู่สินค้าย่อย",
	"PRD.category": "หมวดหมู่สินค้า",
	"PRD.ctype": "หมวดหมู่สินค้าย่อย",
	"PRD.brand": "แบรนด์",
	"PRD.model": "รุ่น",
	"PRD.size": "ขนาด",
	"PRD.color": "สี",
	"PRD.weight": "นำ้หนัก",
	"PRD.unit": "หน่วยหลัก",
	"PRD.taste": "รสชาติ",
	"PRD.content": "ปริมาณ",
	"PRD.packing": "ขนาดบรรจุ",
	"PRD.vat": "VAT",
	"PRD.price": "ราคาขาย",
	"PRD.value": "มูลค่า/หน่วย",
	"PRD.min": "Par stock",
	"PRD.max": "จำนวนสูงสุด",
	"PRD.flag": "ประเภทสินค้า",
	"PRD.balance": "จำนวนคงเหลือ",
	"PRD.desc": "รายละเอียด",
	"PRD.option": "เงื่อนไขเพิ่มเติม",
	"PRD.status.Active": "Active",
	"PRD.status.Inactive": "In-active",

	"PRDentry.code": "รหัสอ้างอิง",
	"PRDentry.code_hint": "Stock Unique ID / IMEI / Serial No",
	"PRDentry.name": "สินค้า",
	"PRDentry.uname": "สินค้า (UoM)",
	"PRDentry.ctype": "หมวดหมู่สินค้าย่อย",
	"PRDentry.brand": "แบรนด์",
	"PRDentry.model": "รุ่น",
	"PRDentry.barcode": "บาร์โค้ด",
	"PRDentry.wlocation": "ที่เก็บสินค้า",
	"PRDentry.description": "รายละเอียด",
	"PRDentry.qty": "จำนวน",
	"PRDentry.unit": "หน่วย",
	"PRDentry.created_time": "วันที่นำเข้า",
	"PRDentry.status_time": "อัพเดทล่าสุด",
	"PRDentry.last_in_time": "นำเข้าล่าสุด",
	"PRDentry.last_out_time": "ขายออกล่าสุด",
	"PRDentry.status.Ready": "พร้อมขาย",
	"PRDentry.status.Checking": "ตรวจสอบ",
	"PRDentry.status.Spare": "สำรอง",
	"PRDentry.status.OutOfOrder": "เสีย",
	"PRDentry.status.Unknown": "ไม่ทราบสถานะ",
	"PRDentry.status.Cancelled": "ยกเลิก",
	"PRDentry.ref_entry": "รายการอ้างอิง",
	"PRDentry.child_codes": "กล่องสินค้า",

	"PRDuom.code": "รหัสสินค้า",
	"PRDuom.code_hint": "Uom Unique ID",
	"PRDuom.photo_main": "รูปภาพ",
	"PRDuom.name": "ชื่อสินค้า",
	"PRDuom.ctype": "หมวดหมู่สินค้าย่อย",
	"PRDuom.brand": "แบรนด์",
	"PRDuom.model": "รุ่น",
	"PRDuom.barcode": "บาร์โค้ด",
	"PRDuom.description": "รายละเอียด",
	"PRDuom.qty": "จำนวน",
	"PRDuom.unit": "หน่วย",
	"PRDuom.factor": "ขนาดบรรจุ",
	"PRDuom.price": "ราคาขาย (Ex-Vat)",
	"PRDuom.width":"ขนาดกว้าง (cm)",
	"PRDuom.length":"ขนาดยาว (cm)",
	"PRDuom.height":"ขนาดสูง (cm)",
	"PRDuom.weight":"น้ำหนัก (kg)",
	"PRDuom.created_time": "วันที่นำเข้า",
	"PRDuom.status_time": "อัพเดทล่าสุด",
	"PRDuom.status.Active": "Active",
	"PRDuom.status.Inactive": "In-active",
	"PRDuom.option": "เงื่อนไขเพิ่มเติม",
	"PRDuom.flag": "ประเภทสินค้า",
	"PRDuom.wholesale": "หน่วยซื้อ",
	"PRDuom.price_shop": "ราคาหน้าร้าน",
	"PRDuom.price_member": "ราคาสมาชิก",
	"PRDuom.price_shop_pos": "ราคาหน้าร้าน",
	"PRDuom.price_member_pos": "ราคาสมาชิก",

	"AST.title": "อุปกรณ์",
	"AST.code": "รหัสอุปกรณ์",
	"AST.name": "ชื่ออุปกรณ์",
	"AST.information": "ข้อมูลอุปกรณ์",
	"AST.photo_main": "รูปภาพ",
	"AST.ctype": "หมวดหมู่อุปกรณ์ย่อย",
	"AST.type": "ประเภทอุปกรณ์",
	"AST.category": "หมวดหมู่อุปกรณ์",
	"AST.brand": "แบรนด์",
	"AST.model": "รุ่น",
	"AST.size": "ขนาด",
	"AST.color": "สี",
	"AST.weight": "นำ้หนัก",
	"AST.unit": "หน่วย",
	"AST.value": "มูลค่า",
	"AST.max": "จำนวนสูงสุด",
	"AST.balance": "จำนวนคงเหลือ",
	"AST.desc": "รายละเอียด",
	"AST.status.Active": "Active",
	"AST.status.Inactive": "In-active",

	"ASTentry.code": "รหัสอ้างอิง",
	"ASTentry.code_hint": "Unique ID / IMEI / Serial No",
	"ASTentry.name": "อุปกรณ์",
	"ASTentry.type": "ประเภทอุปกรณ์",
	"ASTentry.brand": "แบรนด์",
	"ASTentry.model": "รุ่น",
	"ASTentry.wlocation": "ที่เก็บ/ถือครอง",
	"ASTentry.description": "รายละเอียด",
	"ASTentry.qty": "จำนวนคงเหลือ",
	"ASTentry.created_time": "นำเข้าสต็อก",
	"ASTentry.status_time": "สถานะล่าสุด",
	"ASTentry.status.Ready": "พร้อมใช้งาน",
	"ASTentry.status.Checking": "ตรวจสอบ",
	"ASTentry.status.InUsed": "เบิกใช้งาน",
	"ASTentry.status.OutOfOrder": "เสีย",
	"ASTentry.status.Unknown": "ไม่ทราบสถานะ",
	"ASTentry.status.Cancelled": "ยกเลิก",
	"ASTentry.ref_entry": "กล่องสินค้า",

	"SPY.title": "วัสดุสิ้นเปลือง",
	"SPY.code": "รหัสวัสดุ",
	"SPY.name": "ชื่อวัสดุ",
	"SPY.information": "ข้อมูลวัสดุ",
	"SPY.photo_main": "รูปภาพ",
	"SPY.ctype": "หมวดหมู่วัสดุย่อย",
	"SPY.type": "ประเภท",
	"SPY.category": "หมวดหมู่",
	"SPY.brand": "แบรนด์",
	"SPY.model": "รุ่น",
	"SPY.size": "ขนาด",
	"SPY.color": "สี",
	"SPY.weight": "นำ้หนัก",
	"SPY.unit": "หน่วย",
	"SPY.value": "มูลค่า",
	"SPY.max": "จำนวนสูงสุด",
	"SPY.balance": "จำนวนคงเหลือ",
	"SPY.desc": "รายละเอียด",
	"SPY.status.Active": "Active",
	"SPY.status.Inactive": "In-active",

	"SPYentry.code": "รหัสอ้างอิง",
	"SPYentry.code_hint": "รหัส Lot No / กล่อง / ชุด",
	"SPYentry.name": "วัสดุ",
	"SPYentry.type": "ประเภท",
	"SPYentry.brand": "แบรนด์",
	"SPYentry.model": "รุ่น",
	"SPYentry.location": "ที่เก็บ",
	"SPYentry.description": "รายละเอียด",
	"SPYentry.qty": "จำนวนคงเหลือ",
	"SPYentry.created_time": "นำเข้าสต็อก",
	"SPYentry.status_time": "สถานะล่าสุด",
	"SPYentry.status.Ready": "พร้อมใช้งาน",
	"SPYentry.status.Checking": "ตรวจสอบ",
	"SPYentry.status.InUsed": "เบิกใช้งาน",
	"SPYentry.status.OutOfOrder": "เสีย",
	"SPYentry.status.Unknown": "ไม่ทราบสถานะ",
	"SPYentry.status.Cancelled": "ยกเลิก",
	"SPYentry.ref_entry": "รายการอ้างอิง",

	"PRD_good.code" : "รหัสประเภทสินค้า",
	"PRD_good.text" : "ชื่อประเภทสินค้า",
	"PRD_category.code" : "รหัสหมวดหมู่สินค้า",
	"PRD_category.text" : "ชื่อหมวดหมู่สินค้า",
	"PRD_category.ref_code" : "ประเภทสินค้า",
	"PRD_type.code" : "รหัสหมวดหมู่ย่อย",
	"PRD_type.text" : "ชื่อหมวดหมู่ย่อย",
	"PRD_type.ref_code" : "หมวดหมู่สินค้า",
	"PRD_brand.code" : "รหัสแบรนด์สินค้า",
	"PRD_brand.text" : "ชื่อแบรนด์สินค้า",
	"PRD_model.code" : "รหัสรุ่นสินค้า",
	"PRD_model.text" : "ชื่อรุ่นสินค้า",
	"PRD_model.ref_code" : "แบรนด์สินค้า",
	"AST_good.code" : "รหัสประเภทอุปกรณ์",
	"AST_good.text" : "ชื่อประเภทอุปกรณ์",
	"AST_category.code" : "รหัสหมวดหมู่อุปกรณ์",
	"AST_category.ref_code" : "ประเภทอุปกรณ์",
	"AST_category.text" : "ชื่อหมวดหมู่อุปกรณ์",
	"AST_type.code" : "รหัสประเภทอุปกรณ์ย่อย",
	"AST_type.text" : "ชื่อประเภทอุปกรณ์ย่อย",
	"AST_type.ref_code" : "หมวดหมู่อุปกรณ์",
	"SPY_good.code" : "รหัสประเภทวัสดุ",
	"SPY_good.text" : "ชื่อประเภทวัสดุ",
	"SPY_category.code" : "รหัสหมวดหมู่วัสดุ",
	"SPY_category.text" : "ชื่อหมวดหมู่วัสดุ",
	"SPY_category.ref_code" : "ประเภทวัสดุ",
	"SPY_type.code" : "รหัสหมวดหมู่วัสดุย่อย",
	"SPY_type.text" : "ชื่อหมวดหมู่วัสดุย่อย",
	"SPY_type.ref_code" : "หมวดหมู่วัสดุ",
	"location.code" : "รหัสคลังเก็บของ",
	"location.text" : "ชื่อคลังเก็บของ",
	"location.ref_code" : "ประเภทคลัง",
	"unit.code" : "รหัสหน่วยสินค้า",
	"unit.text" : "ชื่อหน่วยสินค้า",
	"VHC_brand.code" : "รหัสแบรนด์",
	"VHC_brand.text" : "ชื่อแบรนด์",
	"sale_area.code" : "รหัสเขตการขาย",
	"sale_area.text" : "ชื่อเขตการขาย",
	"division.code" : "รหัสหน่วยงาน",
	"division.text" : "ชื่อหน่วยงาน",
	"coupon.code" : "รหัสคูปอง",
	"coupon.text" : "ชื่อคูปอง",
	"coupon.value" : "มูลค่า (บาท)",
	"coupon.expire_date" : "วันที่หมดอายุ",
	"coupon.status.A": "Active",
	"coupon.status.N": "In-active",
	"payment.code" : "รหัสการชำระ",
	"payment.text" : "ชื่อการชำระ",
	"payment.logo" : "รูปภาพโลโก้",
	"payment.qrcode" : "รูปภาพ QR Code",
	"payment.status.A": "Active",
	"payment.status.N": "In-active",
	"branch.code" : "รหัสสาขา",
	"branch.text" : "ชื่อสาขา",
	"branch.status.A": "Active",
	"branch.status.N": "In-active",
	"shop_type.code" : "รหัส Shop Type",
	"shop_type.text" : "รายละเอียด Shop Type",

	"DSA.title" : "Best Service Order",
	"DSA.title_description": "รายละเอียดแผนงาน",
	"DSA.title_perform": "ผลการเข้าปฏิบัติงาน",
	"DSA.title_photos":"รูปภาพ",
	"DSA.photo_desc": "รูปภาพ ",
	"DSA.doc_no" : "Doc No.",
	"DSA.doc_date" : "วันที่รับแจ้ง",
	"DSA.doc_time" : "เวลารับแจ้ง",
	"DSA.due_date" : "วัน-เวลาแผนงาน",
	"DSA.due_date_only" : "วันที่กำหนด",
	"DSA.due_time" : "เวลาที่กำหนด",
	"DSA.so_type_desc": "กิจกรรม",
	"DSA.so_type_desc_hint": "ระบุชื่อกิจกรรม",
	"DSA.contact_code": "รหัสลูกค้า",
	"DSA.contact": "ลูกค้า",
	"DSA.customer_phone": "เบอร์โทร",
	"DSA.customer_address": "ที่อยู่",
	"DSA.sale_area": "เขตการขาย",
	"DSA.owner_code": "รหัสผู้ปฏิบัติงาน",
	"DSA.sale_man": "Sale man",
	"DSA.performer": "ผู้ปฏิบัติงาน",
	"DSA.performer_hint": "ไม่ระบุผู้ปฏิบัติงาน",
	"DSA.place": "สถานที่",
	"DSA.coord": "พิกัดตำแหน่งที่ปฏิบัติงาน",
	"DSA.created_user_name": "ผู้เปิดงาน",
	"DSA.approved_user_name": "ผู้ตรวจสอบ",
	"DSA.created_time": "เวลาที่เปิดงาน",
	"DSA.status.Draft": "เอกสารร่าง",
	"DSA.status.Signed": "ยืนยัน",
	"DSA.status.Cancelled": "ยกเลิก",
	"DSA.status.Opened": "เปิดงาน",
	"DSA.status.Noted": "รับงาน",
	"DSA.status.Rejected": "ไม่สามารถรับงานได้",
	"DSA.status.Worked": "เข้าปฏิบัติงาน",
	"DSA.status.CancelNoted": "ยกเลิกการรับงาน",
	"DSA.status.Failed": "ปฏิบัติงานไม่ได้",
	"DSA.status.Closed": "จบงาน",
	"DSA.status.Verified": "ตรวจสอบแล้ว",

	"DSAvisit.title" : "Visit Plan",
	"DSAvisit.doc_date" : "วันที่เปิดงาน",
	"DSAvisit.doc_time" : "เวลาที่วางแผน",

	"airservice.title_for_technician" : "รายละเอียดการปฏิบัติงานช่าง",
	"airservice.title_photos" : "รูปภาพการปฏิบัติงาน",
	"airservice.detail": "ใบแจ้งซ่อม / ติดตั้งอุปกรณ์",
	"airservice.branch" : "สาขา",
	"airservice.project" : "ประเภทงาน",
	"airservice.project_desc" : "ระบุประเภทงาน (อื่นๆ)",
	"airservice.price" : "ราคาที่เสนอ",
	"airservice.address" : "ที่อยู่",
	"airservice.technician" : "ทีม",
	"airservice.phone" : "เบอร์ติดต่อ",
	"airservice.company" : "บริษัท",
	"airservice.desc" : "รายละเอียด",
	"airservice.doc_no" : "เลขงาน",
	"airservice.photo_request1" : "ภาพประกอบ 1",
	"airservice.photo_request2" : "ภาพประกอบ 2",
	"airservice.due_date" : "วันที่นัดหมาย",
	"airservice.service_description" : "รายละเอียดการซ่อม",
	"airservice.remark"  : "หมายเหตุ (สำหรับเจ้าหน้าที่ Admin)",
	"airservice.remark_technician"  : "หมายเหตุ (สำหรับเจ้าหน้าที่ช่าง)",
	"airservice.value_amount":"ราคาก่อน VAT",
	"airservice.total_amount":"ค่าใช้จ่าย",
	"airservice.status": "สถานะงาน",
	"airservice.ref_doc_no": "สถานะเอกสาร",
	"airservice.status.All": "ทั้งหมด",
	"airservice.status.Opened": "เปิดงาน",
	"airservice.status.OnWork": "รับงาน",
	"airservice.status.MakeAppointment": "นัดหมาย",
	"airservice.status.FollowAppointment": "เข้าตามนัดหมาย",
	"airservice.status.Closed": "ปิดงาน",
	"airservice.status.ClosedByTech": "ช่างปิดงาน",
	"airservice.status.Verified": "ตรวจสอบแล้ว",
	"airservice.status.Cancelled": "ยกเลิก",
	"airservice.created_time" : "่แจ้งงาน",
	"airservice.closed_time" : "ปิดงาน",
	"airservice.doc_date" : "วันที่แจ้งงาน",
	"airservice.created_user_name" : "ผู้แจ้ง",
	"airservice.item_no" : "ลำดับ",
	"airservice.item_type" : "ประเภท",
	"airservice.item_info" : "รายการ",
	"airservice.item_price" : "ราคา",
	"airservice.item_total_amount" : "รวม",

	"promotion.title" : "Set รายการโปรโมชั่น",
	"promotion.title_condition" : "เงื่อนไขโปรโมชั่น",
	"promotion.title_rewards" : "Rewards ที่จะได้รับ",
	"promotion.title_limited" : "ข้อกำหนดโปรโมชั่น",
	"promotion.doc_no": "รหัสโปรโมชั่น",
	"promotion.promotion_type": "ประเภทโปรโมชั่น",
	"promotion.promotion_name": "ชื่อโปรโมชั่น",
	"promotion.shop_type": "Shop Type",
	"promotion.sale_area": "เขตการขาย",
	"promotion.district": "ตำบล",
	"promotion.location": "คลังสินค้า",
	"promotion.product": "สินค้า",
	"promotion.photo": "รูปภาพ Banner",
	"promotion.start_date": "เริ่มวันที่",
	"promotion.end_date": "สิ้นสุดวันที่",
	"promotion.brand": "แบรนด์สินค้า",
	"promotion.reward_amount": "ส่วนลดเป็นจำนวนเงิน",
	"promotion.reward_percent": "ส่วนลดเป็นเปอร์เซ็นต์",
	"promotion.reward_product": "สินค้าแถม",
	"promotion.reward_point": "แต้มสะสม",
	"promotion.reward_coupon": "คูปอง",
	"promotion.bill_amount": "ยอดบิลครบ",
	"promotion.except_with_pro":"ไม่สามารถใช้ร่วมกับ Promotion",
	"promotion.min_sku":"ต้องคละอย่างน้อย",
	"promotion.max_amount":"ยอดส่วนลดสูงสุด",
	"promotion.max_product": "สินค้าจำกัด",
	"promotion.max_coupon": "คูปองจำกัด",
	"promotion.unit": "หน่วย",
	"promotion.amount_of_unit": "จำนวน",
	"promotion.step_condition": "ตามเงื่อนไข Step",
	"promotion.paper": "ใบ",
	"promotion.point": "แต้ม",
	"promotion.discount": "ส่วนลด",
	"promotion.item_product": "ซื้อสินค้า",
	"promotion.item_unit": "จำนวน (หน่วย)",
	"promotion.item_amount": "ยอดซื้อ (บาท)",
	"promotion.item_reward": "ได้รับ",
	"promotion.item_runit": "จำนวนที่ได้",
	"promotion.item_point": "+แต้ม",
	"promotion.status.Verified": "ยืนยันเปิดใช้งาน",
	"promotion.status.Active": "เปิดใช้งาน",
	"promotion.status.Inactive": "หยุดใช้งาน",
	"promotion.status.Cancelled": "ยกเลิก",

	"promotiongroup.title" : "กลุ่มสินค้าโปรโมชั่น",
	"promotiongroup.code": "รหัสกลุ่มสินค้า",
	"promotiongroup.group_name": "ชื่อกลุ่มสินค้า",
	"promotiongroup.brand": "แบรนด์",
	"promotiongroup.products": "รายการสินค้าในกลุ่ม",
	"promotiongroup.status" : "สถานะ",
	"promotiongroup.status.A": "ใช้งาน",
	"promotiongroup.status.N": "ไม่ใช้งาน",

	"pos.customer": "ลูกค้า",
	"pos.new_member": "สมัครสมาชิก",
	"pos.not_specify_member": "ลูกค้าทั่วไป",
	"pos.choose_member": "เลือกสมาชิก",
	"pos.create_order": "เปิด Order ใหม่",
	"pos.specify_member": "ระบุสมาชิก",
	"pos.payment": "ชำระเงิน",
	"pos.cancel": "ยกเลิก",
	"pos.total_amount": "ยอดเงินรวม",
	"pos.payment_amount": "ยอดชำระ",
	"pos.pcdiscount_amount" : "ส่วนลดแต้มและคูปอง",
	"pos.vat_amount": "VAT",
	"pos.pdiscount_amount": "ส่วนลดโปรโมชั่น",
	"pos.discount_amount": "ส่วนลดท้ายบิล",
	"pos.number_of_entry": "รวมรายการ",
	"pos.number_of_unit": "รวมชิ้น",
	"pos.warning.select_order_number" : "กรุณาสร้างหรือเลือกหมายเลข Order",
	"pos.warning.maximum_order_number": "ไม่สามารถเปิด Order ใหม่ได้ กรุณาปิด Order ที่ค้างอยู่",
	"pos.warning.barcode_not_found": "ไม่พบรหัส Barcode กรุณาตรวจสอบ",
	"pos.warning.order_empty": "ไม่มีรายการสินค้า",
	"pos.warning.not_enough_qty" : "สต็อกสินค้าไม่เพียงพอ",
	"pos.warning.please_setup_point_use" : "กรุณาตั้งค่าการแลกแต้มส่วนลด",
	"pos.warning.point_not_enough" : "แต้มของลูกค้าไม่เพียงพอ ตามจำนวนแต้มที่ระบุ",
	"pos.confirm.close_order": "ยืนยันการบันทึกและปิด Order นี้ ?",
	"pos.confirm.cancel_order": "ยืนยันการยกเลิก Order นี้ ?",

	"report.query_operation" : "เรียกดูข้อมูล",
	"report.print_date" : "วันที่พิมพ์",
	"report.start_date" : "จากวันที่",
	"report.end_date" : "ถึงวันที่",
	"report.start_time" : "ตั้งแต่เวลา",
	"report.end_time" : "ถึงเวลา",
	"report.sale_person" : "ผู้ขาย",
	"report.so_status" : "สถานะเอกสาร",
	"report.sale_team" : "Sale Team",
	"report.supervisor" : "Supervisor",
	"report.warehouse" : "คลังสินค้า",
	"report.product" : "สินค้า",
	"report.brand" : "แบรนด์สินค้า",
	"report.promotion_code" : "รหัสโปรโมชั่น",

	"notify.please_relogin_to_apply_change" : "เปลี่ยนแปลงข้อมูลการตั้งค่าเรียบร้อย กรุณาLogin ใหม่อีกครั้ง เพื่อใช้งานการตั้งค่าใหม่",
	"warning.warehouse_required" : "ไม่พบการตั้งค่าคลังสินค้า กรุณาติดต่อผู้ดูแลระบบ",
	"warning.owner_sale_required": "ไม่พบข้อมูลที่ปรึกษาการขาย กรุณาติดต่อผู้ดูแลระบบ",

	"filter.start_date" : "จากวันที่",
	"filter.end_date" : "ถึงวันที่",

	"button.verified": "ยืนยันเอกสาร",
	"status.Draft": "เอกสารร่าง",
	"status.Signed": "อนุมัติ",
	"status.Cancelled": "ยกเลิก",
	"status.A": "Active",
	"status.N": "In-active",

	"cash" : "เงินสด",
	"overdue" : "เกินกำหนด",
	"days" : "วัน",
	"times" : "เท่า",
	"cu" : "ราย",
	"signature" : "ลายเซ็น",
	"address" : "ที่อยู่",
	"phone" : "โทรศัพท์",
	"fax" : "โทรสาร",
	"taxno" : "เลขประจำตัวผู้เสียภาษี",
	"branch" : "สำนักงาน/สาขา",
	"information" : "ข้อมูล",
	"created_approved_user_name" : "ผู้เปิด/อนุมัติเอกสาร",
	"created_user_name" : "ผู้เปิดเอกสาร",
	"created_date" : "วันที่สร้าง",
	"last_updated_user_name" : "ผู้แก้ไขล่าสุด",
	"last_updated_date" : "วันที่แก้ไข",
	"approved_user_name" : "ผู้อนุมัติ",
	"approved_date" : "วันที่ยืนยัน"
}
